import { motion } from "framer-motion"
import { Link } from "gatsby"
import React from "react"
import { useMedia, useWindowScroll } from "react-use"
import styled from "styled-components"

const Container = styled(motion.nav)`
    padding: ${props => (props.scrolled ? "10px 20px" : "15px 20px")};
    background: ${props =>
        props.scrolled ? (props.dark ? "black" : "white") : "transparent"};
    position: fixed;
    z-index: 99;
    transition: 0.2s ease;
    right: 0;
    left: 0;
    top: 0;
    color: ${props =>
        props.dark
            ? props.scrolled
                ? "white"
                : "black"
            : props.scrolled
            ? "black"
            : "white"} !important;
`
const Logo = styled.img`
    max-width: 120px;
    flex: 1;

    @media (max-width: 550px) {
        max-width: 100px;
    }
`
const Nav = styled.nav`
    display: grid;
    /* grid-template-columns: auto auto 1fr auto auto; */
    grid-template-columns: 1fr 1fr auto 1fr 1fr;
    gap: 5px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
`
const LinkItem = styled(Link)`
    font-size: 16px;
    text-transform: uppercase;
    font-family: "Haas";
    text-decoration: none;
    text-align: center;
    color: inherit;
    white-space: nowrap;
    transition: 0.2s ease;

    :hover {
        text-decoration: underline;
    }

    @media (max-width: 550px) {
        font-size: 12px;
    }
`

const Navbar = ({ dark, animate }) => {
    const isWide = useMedia("(min-width: 550px)")
    const { y } = useWindowScroll()

    const scrolled = y > 40

    return (
        <Container
            dark={dark}
            scrolled={scrolled}
            initial={{ y: animate ? "-200%" : 0, opacity: animate ? 0 : 1 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1.2, duration: 1, ease: "easeOut" }}
        >
            <Nav>
                <LinkItem
                    activeStyle={{ textDecoration: "underline" }}
                    to="/resources"
                >
                    Resources
                </LinkItem>
                <LinkItem
                    activeStyle={{ textDecoration: "underline" }}
                    to="/we-phealt-that"
                >
                    We Phealt
                </LinkItem>
                <Link to="/" style={{ textAlgin: "center" }}>
                    {isWide ? (
                        <Logo
                            src={require(`../../images/Pheal-Logo-${
                                dark
                                    ? scrolled
                                        ? "White"
                                        : "Black"
                                    : scrolled
                                    ? "Black"
                                    : "White"
                            }.svg`)}
                        />
                    ) : (
                        <Logo
                            src={require(`../../images/Pheal-Logo-${
                                dark
                                    ? scrolled
                                        ? "White"
                                        : "Black"
                                    : scrolled
                                    ? "Black"
                                    : "White"
                            }-S.svg`)}
                        />
                    )}
                </Link>
                <LinkItem
                    activeStyle={{ textDecoration: "underline" }}
                    to="/shop"
                >
                    Shop
                </LinkItem>
                <LinkItem
                    to="/how-it-works"
                    activeStyle={{ textDecoration: "underline" }}
                >
                    Join
                </LinkItem>
            </Nav>
        </Container>
    )
}

export default Navbar
