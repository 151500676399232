import React from 'react'
import styled from 'styled-components';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { motion } from 'framer-motion';
import { IoIosCloseCircleOutline } from 'react-icons/io'
import {useSetRecoilState} from 'recoil';
import { currentAudioTrack } from "../state/atoms"

export default function CustomAudioPlayer({ audio }){
    const setAudio = useSetRecoilState(currentAudioTrack)

    return (
        <Wrapper
            initial={{opacity: 0, y: '100%'}}
            animate={{opacity: 1, y: 0}}
            exit={{opacity: 0, y: '100%'}}
            transition={{duration: 0.5, type: 'tween'}}
        >
            <Container>
                <CloseBtn onClick={() => setAudio(null)}>
                    <IoIosCloseCircleOutline size={40} color="white"/>
                </CloseBtn>
                <AudioPlayer
                    autoPlay
                    src={audio.url}
                    onPlay={e => console.log("onPlay")}
                    showSkipControls={false}
                    showJumpControls={false}
                    style={{background: 'transparent'}}
                    customAdditionalControls={[
                        <div><TrackName>{audio.title}</TrackName></div>,
                        // RHAP_UI.ADDITIONAL_CONTROLS,
                        // RHAP_UI.MAIN_CONTROLS,
                        // RHAP_UI.VOLUME_CONTROLS,
                    ]}
                    // other props here
                />
            </Container>

        </Wrapper>
    )
}

const Wrapper = styled(motion.div)`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    padding-top: 50px;
    background: rgba(0,0,0,0.95);
`;
const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
`;
const TrackName = styled.p`
    color: white;
    font-size: 18px;
    font-weight: 500;
`;
const CloseBtn = styled.button`
    background: none;
    outline: none;
    border: none;
    position: absolute;
    top: 5px;
    right: 10px;
`;