import { atom } from "recoil"

export const contentType = atom({
    key: "contentType",
    default: "",
})
export const colorScheme = atom({
    key: "colorScheme",
    default: "dark",
})
export const nameField = atom({
    key: "name",
    default: "",
})
export const emailField = atom({
    key: "email",
    default: "",
})
export const showingMailingPopup = atom({
    key: "showingMailingPopup",
    default: false,
})
export const menuOpen = atom({
    key: "menuOpen",
    default: false,
})
export const currentAudioTrack = atom({
    key: "currentAudioTrack",
    default: null
})
