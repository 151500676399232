import { motion } from "framer-motion"
import styled from "styled-components"

export const DigestiveText = styled(motion.h1)`
    font-family: "Digestive";
    font-size: ${props => (props.fontSize ? `${props.fontSize}px` : "50px")};
    margin-bottom: ${props => (props.mBottom ? `${props.mBottom}px` : "0px")};
    text-transform: ${props => (props.noUppercase ? "none" : "uppercase")};
`
export const HeadlineText = styled(motion.h1)`
    font-family: 'Quattrocento';
    font-weight: 400;
    font-size: ${props => (props.fontSize ? `${props.fontSize}px` : "50px")};
    margin-bottom: ${props => (props.mBottom ? `${props.mBottom}px` : "0px")};
`

export const BodyText = styled(motion.p)`
    font-family: "Haas";
    font-weight: 400;
    font-size: ${props => (props.fontSize ? `${props.fontSize}px` : "16px")};
    font-size: var(--how-it-works-body-font-size);
    margin-bottom: ${props => (props.mBottom ? `${props.mBottom}px` : "0px")};
    line-height: 1.7;
`
export const BodyCopy = styled(motion.p)`
    font-family: "Haas";
    font-weight: 400;
    font-size: var(--body-text-font-size);
    margin-bottom: ${props => (props.mBottom ? `${props.mBottom}px` : "0px")};
    line-height: 1.5;
`
export const Heading = styled(motion.p)`
    font-family: "Haas";
    font-weight: 500;
    font-size: var(--heading-font-size);
    margin-bottom: ${props => (props.mBottom ? `${props.mBottom}px` : "16px")};
    line-height: 1.7;
`
