import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  html {
    --how-it-works-body-font-size: 18px;
      --body-text-font-size: 17px;
      --heading-font-size: 19px;

    @media (min-width: 600px) {
      --how-it-works-body-font-size: 20px;
      --body-text-font-size: 18px;
      --heading-font-size: 24px;
    }
  }
`
