import styled from "styled-components"

export const MainButton = styled.button`
    background: ${props => (props.dark ? "black" : "white")};
    color: ${props => (props.dark ? "white" : "black")};
    text-align: center;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 15px 30px;
    border: none;
    outline: none;
    cursor: pointer;

    :focus {
        outline: none;
    }

    :disabled {
        background: #707070;
    }
`
export const OutlineButton = styled.button`
    border: ${props => (props.dark ? "1px solid black" : "1px solid white")};
    color: ${props => (props.dark ? "black" : "white")};
    text-align: center;
    text-transform: uppercase;
    padding: ${props => props.small ? '10px 25px' : '15px 35px'};
    outline: none;
    cursor: pointer;
    transition: 0.2s ease-out;
    background: none;
    min-width: 150px;
    font-size: ${props => props.small ? '12px' : '15px'};
    :hover {
        background: ${props => (props.dark ? "black" : "white")};
        color: ${props => (props.dark ? "white" : "black")};
    }
    :focus {
        outline: none;
    }
    :disabled {
        /* background: #707070; */
        border: 1px solid #707070;
        color: #707070;
        :hover {
            background: none;
        }
    }
`
